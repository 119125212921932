import { Alert } from '@toasttab/buffet-pui-alerts'
import * as React from 'react'
import { RestaurantHeaderV2 } from '../../../components/Menus/RestaurantHeaderV2/RestaurantHeaderV2'

export interface FullScreenErrorProps {
  children: React.ReactNode
  title?: string
  retry?(): void
}

export const FullScreenError = ({
  children,
  retry,
  title
}: FullScreenErrorProps) => {
  const [retryCount, setRetryCount] = React.useState(0)

  return (
    <div className='flex flex-col justify-center pb-6'>
      <RestaurantHeaderV2 />)
      <div className='mx-6'>
        <Alert
          testId='full-screen-error-alert'
          variant={retry ? 'error' : 'warning'}
          showIcon
          className='w-full'
        >
          <h5 className='font-semibold type-headline-5'>
            {title || 'Unable to load the page'}
          </h5>
          <div className='py-2'>{children}</div>
          {retry && (
            <button
              className='block px-0 py-2 font-semibold underline bg-transparent border-none text-default'
              onClick={() => {
                setRetryCount(retryCount + 1)
                retry()
              }}
              data-testid='full-screen-error-retry'
            >
              Click here to retry
            </button>
          )}
          {retryCount > 0 && (
            <span
              className='font-semibold underline text-default'
              onClick={() => {
                window.location.reload()
              }}
            >
              You can also try refreshing this page.
            </span>
          )}
        </Alert>
      </div>
    </div>
  )
}
