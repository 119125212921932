import { OptionButton } from '@local/option-button'
import * as React from 'react'
import PayForMyItemsIcon from '../../../../src/assets/pay-for-my-items-icon.svg'
import PayTabBalanceIcon from '../../../../src/assets/pay-tab-balance-icon.svg'

import styles from './PaymentOptions.module.css'

export interface DefaultPaymentOptionsProps {
  handleOnSplit: () => Promise<void>
  handlePayBalance: () => void
  hasItems: boolean
}

export const DefaultPaymentOptions = ({
  handleOnSplit,
  handlePayBalance,
  hasItems
}: DefaultPaymentOptionsProps) => {
  const [loading, setLoading] = React.useState(false)

  return (
    <>
      {hasItems && (
        <tr className={styles.paymentOptionRow}>
          <td>
            <OptionButton
              disabled={loading}
              loading={loading}
              icon={<PayForMyItemsIcon />}
              text='Pay for my items'
              onClick={async () => {
                setLoading(true)
                await handleOnSplit()
                setLoading(false)
              }}
              data-testid='pay-for-my-items-button'
            />
          </td>
        </tr>
      )}
      <tr className={styles.paymentOptionRow}>
        <td>
          <OptionButton
            icon={<PayTabBalanceIcon />}
            text='Pay tab balance'
            onClick={handlePayBalance}
            data-testid='pay-tab-balance-button'
          />
        </td>
      </tr>
    </>
  )
}
