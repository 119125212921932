import { OptionButton } from '@local/option-button'
import { JoinOrCreatePartyMode, PartyVisibility } from '@local/shared-types'
import { joinOrCreateParty } from 'cornucopia-apis'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ORDERING_ROUTES } from '../../../../src/app/OrderingRoutes'
import OrderGroupIcon from '../../../../src/assets/order-group.svg'
import OrderSoloIcon from '../../../../src/assets/order-solo.svg'
import {
  TransitionDirection,
  useTransitionContext
} from '../../../../src/components/TransitionProvider/TransitionContext'

export interface SplashScreenProps {
  tableName: string
  onClose: () => void
}

export const SplashScreen = ({ tableName, onClose }: SplashScreenProps) => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const { setTransition } = useTransitionContext()

  const onSoloSelection = () => {
    setLoading(true)
    joinOrCreateParty({
      mode: JoinOrCreatePartyMode.CREATE,
      visibility: PartyVisibility.PRIVATE
    }).subscribe((result) => {
      setLoading(false)
      if (result.kind === 'OK') {
        onClose()
      }
    })
  }
  return (
    <div data-testid='splash-screen' className='mx-6 mt-6'>
      <div className='flex flex-col items-center'>
        <div className='font-semibold type-overline text-gray-75'>
          WELCOME · TABLE {tableName}
        </div>
        <div className='text-default type-headline-3'>Let's get started</div>
        <div className='w-full mt-10 mb-24'>
          <div className='border border-solid rounded-lg border-gray-30'>
            <OptionButton
              disabled={loading}
              loading={loading}
              icon={<OrderSoloIcon />}
              text='Order on my own'
              onClick={onSoloSelection}
              data-testid='solo-dining-option-button'
              className='rounded-lg'
            />
            <div className='w-full h-[1px] bg-gray-30 '></div>
            <OptionButton
              disabled={loading}
              icon={<OrderGroupIcon />}
              text='Order as a group'
              onClick={() => {
                setTransition(TransitionDirection.ForwardDefault)

                navigate(ORDERING_ROUTES.JOIN)
              }}
              data-testid='group-dining-option-button'
              className='rounded-lg'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
